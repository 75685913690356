import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectToken, userData } from "../../store/selectors/authSelectors";

export const AppPrivateRoute = ({ children }) => {
  const token = useSelector(selectToken);
  const data = useSelector(userData);
  if (!token) {
    return <Navigate to="/" replace />;
  } else if (token && data?.userSettings?.needToChangePassword) {
    return <Navigate to="/create-new-password" replace />;
  }

  return children ? children : <Outlet />;
};
