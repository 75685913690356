import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchDeleteRequest, fetchGetRequest, fetchPatchRequest, fetchPostRequest} from '../../libs/network';
import endpoints from '../../utils/endpoints';
import toast from 'react-hot-toast';
import { logoutUser } from './authSlice';
import { deleteModalHide } from './modals';

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  deals: null,
  editDeal: null,
  dealsData: null,
};

export const getDeal = createAsyncThunk(
  'deal/getDeal',
  async ({ pageNum, pageSize }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getDeal,
        {
          params: {
            page: pageNum || undefined,
            size: pageSize || undefined,
          },
        },
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Deals");
      console.log('get deal err', JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const createDeal = createAsyncThunk(
  'deal/createDeal',
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createDeal,
        payload,
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('create deal err', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Deal");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const updateDeal = createAsyncThunk(
  "deal/updateDeal",
  async ({payload, restaurantDealId}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.updateDeal(restaurantDealId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update deal err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Deal");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const deactivateDeal = createAsyncThunk(
  "deal/deactivateDeal",
  async ({payload, dealId}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.deactivateDeal(dealId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("deal err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed, Please try again later");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getDealById = createAsyncThunk(
  'deal/getDealById',
  async (id, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getDealById(id),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get deal err', JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const getDealAdmin = createAsyncThunk(
  'deal/getDealAdmin',
  async ({ adminDealId, pageNum, pageSize }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getDealAdmin(adminDealId),
        {
          params: {
            page: pageNum || undefined,
            size: pageSize || undefined,
          },
        },
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Deals");
      console.log('get deal err', JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const createAdminDeal = createAsyncThunk(
  'deal/createAdminDeal',
  async ({restaurantId, payload}, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createAdminDeal(restaurantId),
        payload,
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('create deal err', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Deal");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const updateAdminDeal = createAsyncThunk(
  "deal/updateAdminDeal",
  async ({payload, restaurantId, restaurantDealId}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.updateAdminDeal(restaurantId, restaurantDealId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update deal err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Deal");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const deactivateAdminDeal = createAsyncThunk(
  "deal/deactivateAdminDeal",
  async ({payload, dealId}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.deactivateAdminDeal(dealId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("deal err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed, Please try again later");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getAdminDealById = createAsyncThunk(
  'deal/getAdminDealById',
  async ({restaurantId, idToFetch}, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAdminDealById(restaurantId, idToFetch),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get deal err', JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteDeal = createAsyncThunk(
  'deal/deleteDeal',
  async (restaurantDealId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteDeal(restaurantDealId),
      );
      toast.success(response?.data?.message || "Deal deleted Successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('deal', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Deal");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteAdminDeal = createAsyncThunk(
  'deal/deleteAdminDeal',
  async (restaurantDealId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteAdminDeal(restaurantDealId),
      );
      toast.success(response?.data?.message || "Deal deleted Successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('deal', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Deal");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const {reducer, actions} = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    dealsData: (state, action) => {
      state.dealsData = action.payload;
    },
    resetDealsData: () => {
      return initialState;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(getDeal.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDeal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deals = action?.payload;        
      })
      .addCase(getDeal.rejected, state => {
        state.isLoading = false;
      })

      .addCase(getDealById.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDealById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editDeal = action?.payload;
      })
      .addCase(getDealById.rejected, state => {
        state.isLoading = false;
      })

      .addCase(createDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(createDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(createDeal.rejected, state => {
        state.isPopupLoading = false;
      })
      
      .addCase(updateDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(updateDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(updateDeal.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(deactivateDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deactivateDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(deactivateDeal.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(getDealAdmin.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDealAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deals = action?.payload;        
      })
      .addCase(getDealAdmin.rejected, state => {
        state.isLoading = false;
      })

      .addCase(createAdminDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(createAdminDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(createAdminDeal.rejected, state => {
        state.isPopupLoading = false;
      })
      
      .addCase(updateAdminDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(updateAdminDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(updateAdminDeal.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(deactivateAdminDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deactivateAdminDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(deactivateAdminDeal.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(getAdminDealById.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAdminDealById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editDeal = action?.payload;        
      })
      .addCase(getAdminDealById.rejected, state => {
        state.isLoading = false;
      })

      .addCase(deleteDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deleteDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(deleteDeal.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(deleteAdminDeal.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deleteAdminDeal.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(deleteAdminDeal.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(logoutUser, (state) => {
        state.deals = null;
        state.editDeal = null;
        state.dealsData = null;
      }),

});

export default reducer;
export const {dealsData, resetDealsData} = actions;