import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDeleteRequest, fetchGetRequest, fetchMultipartPatchRequest, fetchMultipartPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { logoutUser } from "./authSlice";
import { deleteModalHide, deleteRestaurantProfileModalHide } from "./modals";

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  isDeletePopupLoading: false,
  restaurantDetails: null,
  restaurantId: null,
};

export const getRestaurantLocationOwnerDetail = createAsyncThunk(
  "restaurantLocationOwner/getRestaurantLocationOwnerDetail",
  async (restaurantLocationId, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getRestaurantLocationOwnerDetail(restaurantLocationId),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message);
      console.log("get restaurantDetail err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createRestaurantLocationOwner = createAsyncThunk(
  "restaurantLocationOwner/createRestaurantLocationOwner",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPostRequest(
        endpoints.dashboard.createRestaurantLocationOwner,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("create restaurant location err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Restaurant Location");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateRestaurantLocationOwner = createAsyncThunk(
  "restaurantLocationOwner/updateRestaurantLocationOwner",
  async ({payload, addressId}, thunkAPI) => {
    try {
      const response = await fetchMultipartPatchRequest(
        endpoints.dashboard.updateRestaurantLocationOwner(addressId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update restaurant location err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Restaurant Location");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateRestaurantLocationProfile = createAsyncThunk(
  "restaurantLocationOwner/updateRestaurantLocationProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPatchRequest(
        endpoints.dashboard.updateRestaurantLocationProfile,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update restaurant profile err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Restaurant Profile");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const deleteAdminRestaurantLocation = createAsyncThunk(
  'restaurantLocationOwner/deleteAdminRestaurantLocation',
  async ({restaurantId, addressId}, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteAdminRestaurantLocation(restaurantId, addressId),
      );
      toast.success(response?.data?.message || "Restaurant Location deleted Successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('restaurant location', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Restaurant Location");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteRestaurantLocationImages = createAsyncThunk(
  'restaurantLocationOwner/deleteRestaurantLocationImages',
  async ({restaurantId, restaurantImageId}, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteRestaurantLocationImages(restaurantId, restaurantImageId),
      );
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      console.log('restaurant images', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Restaurant Images");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const { reducer, actions } = createSlice({
  name: "restaurantLocationOwner",
  initialState,
  reducers: {
    restaurantId: (state, action) => {
      state.restaurantId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getRestaurantLocationOwnerDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurantLocationOwnerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restaurantDetails = action.payload.restaurant;
      })
      .addCase(getRestaurantLocationOwnerDetail.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createRestaurantLocationOwner.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createRestaurantLocationOwner.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createRestaurantLocationOwner.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateRestaurantLocationOwner.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateRestaurantLocationOwner.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateRestaurantLocationOwner.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateRestaurantLocationProfile.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateRestaurantLocationProfile.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateRestaurantLocationProfile.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(deleteAdminRestaurantLocation.pending, state => {
        state.isDeletePopupLoading = true;
      })
      .addCase(deleteAdminRestaurantLocation.fulfilled, (state, action) => {
        state.isDeletePopupLoading = false;
        
      })
      .addCase(deleteAdminRestaurantLocation.rejected, state => {
        state.isDeletePopupLoading = false;
      })

      .addCase(logoutUser, (state) => {
        state.restaurantDetails = null;
        state.restaurantId = null;
      }),
});

export default reducer;
export const {restaurantId} = actions;
