import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMultipartPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { logoutUser } from "./authSlice";

const initialState = {
  isLoading: false,
  progress: 0,
};

export const uploadFoodItemsFile = createAsyncThunk(
  "importCSV/uploadFoodItemsFile",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPostRequest(
        endpoints.dashboard.uploadFoodItemsFile,
        payload,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            thunkAPI.dispatch(setUploadProgress(progress));
          },
        }
      );
      thunkAPI.dispatch(resetUploadProgress());
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("upload food item err", JSON.stringify(err, null, 4));
      thunkAPI.dispatch(resetUploadProgress());
      toast.error(err?.data?.message || "Failed to upload File");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const uploadRestaurantFile = createAsyncThunk(
  "importCSV/uploadRestaurantFile",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPostRequest(
        endpoints.dashboard.uploadRestaurantFile,
        payload,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            thunkAPI.dispatch(setUploadProgress(progress));
          },
        }
      );
      thunkAPI.dispatch(resetUploadProgress());
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("upload restaurant err", JSON.stringify(err, null, 4));
      thunkAPI.dispatch(resetUploadProgress());
      toast.error(err?.data?.message || "Failed to upload File");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "importCSV",
  initialState,
  reducers: {
    setUploadProgress: (state, action) => {
      state.progress = action.payload;
    },
    resetUploadProgress: (state) => {
      state.progress = 0;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(uploadFoodItemsFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFoodItemsFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.progress = 0;
      })
      .addCase(uploadFoodItemsFile.rejected, (state) => {
        state.isLoading = false;
        state.progress = 0;
      })

      .addCase(uploadRestaurantFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadRestaurantFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.progress = 0;
      })
      .addCase(uploadRestaurantFile.rejected, (state) => {
        state.isLoading = false;
        state.progress = 0;
      })

      .addCase(logoutUser, (state) => {
        state.progress = 0;
      }),
});

export default reducer;
export const { setUploadProgress, resetUploadProgress } = actions;