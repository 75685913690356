import React, { useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Loading from "../../../components/loading";
import { useDispatch } from "react-redux";
import { constant } from "../../../constants";
import { editRestaurantCategoryforAdmin, getRestaurantCategoryForAdminById } from "../../../../store/reducers/restaurantCategory";
import "./restaurant-category.scss";

const EditRestaurantCategory = ({ closePopup, restaurantCategoryData, getRestaurantCategory, selectedRestaurantCategoryId }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();

  useEffect(() => {
    dispatch(
      getRestaurantCategoryForAdminById({
        restaurant_category_id: selectedRestaurantCategoryId,
      })
    ).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const data = response?.payload?.restaurantCategory || {};
          if (data) {
            formikRef?.current?.setFieldValue(
              "restaurantCategoryName",
              data?.restaurantCategoryName
            );
            formikRef?.current?.setFieldValue("description", data?.description);
          }
          return;

        default:
          return;
      }
    });
  }, [dispatch]);

  const handleCreateRestaurantCategory = async (values) => {
    const payload = {
      restaurantCategoryName: values.restaurantCategoryName,
      description: values.description,
    };

    dispatch(editRestaurantCategoryforAdmin({payload, restaurant_category_id: selectedRestaurantCategoryId })).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          closePopup();
          getRestaurantCategory();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Edit Restaurant Category</h2>
        <i onClick={closePopup} className="bx bx-x"></i>
        <div>
          {restaurantCategoryData?.isLoading && (
            <div className="tableLoader">
              <div
                style={{ borderRadius: "30px", top: "0" }}
                className="spinnerLoad"
              >
                <Loading></Loading>
              </div>
            </div>
          )}
          <Formik
            innerRef={formikRef}
            initialValues={{
              restaurantCategoryName: "",
              description: "",
            }}
            validationSchema={Yup.object().shape({
              restaurantCategoryName: Yup.string()
                .required("Restaurant Category Name is required")
                .max(
                  50,
                  "Restaurant Category Name cannot exceed 50 characters"
                ),
            })}
            onSubmit={(values, { setSubmitting }) =>
              handleCreateRestaurantCategory(values, setSubmitting)
            }
          >
            {({ isSubmitting, isValid }) => {
              return (
                <Form>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="restaurantCategoryName">
                          Restaurant Category Name
                        </label>
                        <Field
                          type="text"
                          name="restaurantCategoryName"
                          id="restaurantCategoryName"
                          placeholder="Restaurant Category Name"
                          component={Input}
                        />
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          placeholder="Description"
                          component={Input}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="btn"
                  >
                    {restaurantCategoryData?.isPopupLoading && (
                      <Loading></Loading>
                    )}
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default EditRestaurantCategory;
