import React, { useEffect, useState } from "react";
import Layout from "../../../layouts/dashboard-layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMenuItemforAdminDeal, getMenuItemforDeal } from "../../../../store/reducers/menu";
import { selectDealData, selectMenuItemsData, selectRestaurantLocationData } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import Loading from "../../../components/loading";
import SuccessPopup from "./successPopup";
import "./selectRelatedMenuItems.scss";
import toast from "react-hot-toast";
import { createAdminDeal, createDeal, resetDealsData, updateAdminDeal, updateDeal } from "../../../../store/reducers/deal";
import Button from "../../../components/button";
import { selectIsSuccessModalOpen } from "../../../../store/selectors/modalSelectors";
import { successModalHide, successModalOpen } from "../../../../store/reducers/modals";
import { setRestaurantDealId } from "../../../../store/reducers/authSlice";
import { userData } from "../../../../store/selectors/authSelectors";

const SelectRelatedMenuItems = () => {
  const menuItemData = useSelector(selectMenuItemsData);
  const dealFormData = useSelector(selectDealData);
  const [foods, setFoods] = useState(menuItemData?.menuItemsDeal);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const isSuccessPopupOpen = useSelector(selectIsSuccessModalOpen);
  const [parentCheckboxes, setParentCheckboxes] = useState({});
  const [childCheckboxes, setChildCheckboxes] = useState({});
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [selectedFoodIds, setSelectedFoodIds] = useState([]);
  const restaurantDealId = dealFormData?.dealsData?.selectedRestaurantDealId;
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantId = restaurantDetailData?.restaurantId;
  const user = useSelector(userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    if (user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin) {
      navigate("/dashboard/deals");
    } else {
      navigate("/dashboard/restaurant-location-owner", {
        state: { activeTab: "deal-tab" },
      });
    }
  };

  useEffect(() => {
    if (Array.isArray(dealFormData?.dealsData?.isFoodDeals)) {
      const foodIds = dealFormData?.dealsData?.isFoodDeals?.map(
        (food) => food.foodId
      );
      const updatedChildCheckboxes = { ...childCheckboxes };
      const updatedParentCheckboxes = { ...parentCheckboxes };

      // Update child checkboxes based on foodIds
      for (const foodId of foodIds) {
        updatedChildCheckboxes[foodId] = true;
      }

      // Check each category to see if all children are checked
      const foodCategories = foods?.reduce((acc, food) => {
        if (!acc[food?.foodCategory?.categoryId]) {
          acc[food?.foodCategory?.categoryId] = [];
        }
        acc[food?.foodCategory?.categoryId].push(food?.id);
        return acc;
      }, {});

      for (const categoryId in foodCategories) {
        const allChecked = foodCategories[categoryId].every(
          (foodId) => updatedChildCheckboxes[foodId]
        );
        updatedParentCheckboxes[categoryId] = allChecked;
      }

      setChildCheckboxes(updatedChildCheckboxes);
      setParentCheckboxes(updatedParentCheckboxes);
    }
  }, [dealFormData?.dealsData?.isFoodDeals]);

  const handleParentCheckboxChange = (categoryId) => {
    const isChecked = !parentCheckboxes[categoryId];

    setParentCheckboxes((prevParentCheckboxes) => ({
      ...prevParentCheckboxes,
      [categoryId]: isChecked,
    }));

    setChildCheckboxes((prevChildCheckboxes) => {
      const updatedChildCheckboxes = { ...prevChildCheckboxes };
      foods?.forEach((food) => {
        if (food?.data) {
          food?.data?.forEach((item) => {
            if (item?.foodCategory?.categoryId === categoryId) {
              updatedChildCheckboxes[item?.id] = isChecked;
            }
          });
        }
      });

      const selectedIds = Object.keys(updatedChildCheckboxes).filter(
        (key) => updatedChildCheckboxes[key]
      );
      setSelectedFoodIds(selectedIds);

      return updatedChildCheckboxes;
    });
  };

  const handleChildCheckboxChange = (itemId) => {
    const newChildCheckboxes = { ...childCheckboxes };
    const isChecked = !newChildCheckboxes[itemId];

    newChildCheckboxes[itemId] = isChecked;
    setChildCheckboxes(newChildCheckboxes);

    const selectedIds = Object.keys(newChildCheckboxes).filter(
      (key) => newChildCheckboxes[key]
    );
    setSelectedFoodIds(selectedIds);

    const parentCategoryId = foods?.find((food) =>
      food?.data?.some((item) => item?.id === itemId)
    )?.data[0]?.foodCategory.categoryId;
    if (parentCategoryId) {
      const parentChecked = foods
        .find((food) => food?.data?.some((item) => item?.id === itemId))
        .data.every((item) => newChildCheckboxes[item?.id]);
      setParentCheckboxes({
        ...parentCheckboxes,
        [parentCategoryId]: parentChecked,
      });
    }
  };

  useEffect(() => {
    const fetchMenuItemsForDealFunction = user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin ? () => dispatch(getMenuItemforDeal())
    : () => dispatch(getMenuItemforAdminDeal(restaurantId));
  
    fetchMenuItemsForDealFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const grouped = response?.payload?.foods?.reduce((acc, item) => {
            if (!acc[item?.foodCategory?.categoryName]) {
              acc[item?.foodCategory?.categoryName] = {
                categoryName: item?.foodCategory?.categoryName,
                data: [],
              };
            }
            acc[item?.foodCategory?.categoryName].data.push(item);
            return acc;
          }, {});
          const sortedCategories = Object.values(grouped).sort((a, b) =>
            a.categoryName.localeCompare(b.categoryName)
          );
          setFoods(sortedCategories);
          setIsDataLoaded(true);
          return;
  
        default:
          return;
      }
    });
    dispatch(successModalHide());
    return () => {
      dispatch(successModalHide());
    };
  }, [dispatch, restaurantId, user.userType, user.userSettings]);

  useEffect(() => {
    const anyCheckboxChecked = Object.values(childCheckboxes).some(Boolean);
    setIsAnyCheckboxChecked(anyCheckboxChecked);
  }, [childCheckboxes]);

  const handleDeal = async () => {
    const payload = {
      dealTitle: dealFormData?.dealsData?.dealTitle,
      foodIds:
        selectedFoodIds.length > 0
          ? selectedFoodIds
          : dealFormData.dealsData.isFoodDeals.map((food) => food.foodId),
      startDate: dealFormData?.dealsData?.startDate,
      ...(dealFormData?.dealsData?.claimPerUser !== undefined && { claimPerUser: dealFormData.dealsData.claimPerUser }),
      ...(dealFormData?.dealsData?.endDate && dealFormData.dealsData.endDate !== "" && { endDate: dealFormData.dealsData.endDate }),
      isActive: dealFormData?.dealsData?.isActive,
    };

    if (restaurantId) {
      if (restaurantDealId) {
        dispatch(updateAdminDeal({ payload, restaurantId, restaurantDealId })).then((response) => {
          switch (response?.meta?.requestStatus) {
            case constant.thunkStatus.FULFILLED:
              toast.success(
                response?.payload?.message || "Deal updated successfully"
              );
              dispatch(resetDealsData());
              navigate("/dashboard/restaurant-location-owner", { state: { activeTab: "deal-tab" } });
              return;
  
            default:
              return;
          }
        });
      } else {
        dispatch(createAdminDeal({restaurantId, payload})).then((response) => {
          switch (response?.meta?.requestStatus) {
            case constant.thunkStatus.FULFILLED:
              dispatch(setRestaurantDealId(response?.payload?.dealId));
              toast.success(
                response?.payload?.message || "Deal created successfully"
              );
              dispatch(successModalOpen());
              dispatch(resetDealsData());
              return;
  
            default:
              return;
          }
        });
      }
    }
    else {
      if (restaurantDealId) {
        dispatch(updateDeal({ payload, restaurantDealId })).then((response) => {
          switch (response?.meta?.requestStatus) {
            case constant.thunkStatus.FULFILLED:
              toast.success(
                response?.payload?.message || "Deal updated successfully"
              );
              dispatch(resetDealsData());
              navigate("/dashboard/deals");
              return;
  
            default:
              return;
          }
        });
      } else {
        dispatch(createDeal(payload)).then((response) => {
          switch (response?.meta?.requestStatus) {
            case constant.thunkStatus.FULFILLED:
              dispatch(setRestaurantDealId(response?.payload?.dealId));
              toast.success(
                response?.payload?.message || "Deal created successfully"
              );
              dispatch(successModalOpen());
              dispatch(resetDealsData());
              return;
  
            default:
              return;
          }
        });
      }
    }
  };

  return (
    <Layout>
      <div className="homeContent prcon20">
        <div className="bodyTopsec">
          <h2>Select Related Menu Items</h2>
          <div className="iconSce">
              <Button className="cancleBtn2" type="submit" onClick={handleBack}>
                Back
              </Button>
            {isAnyCheckboxChecked && (
              <Button className="doneBtn3" type="submit" onClick={handleDeal}>
                <div className="doneBtnLoader">
                  {dealFormData?.isPopupLoading && <Loading />}
                  Done
                </div>
              </Button>
            )}
          </div>
        </div>
        {!isAnyCheckboxChecked && (
            <p className="alertMessage">*Please select at least one menu item to create a deal</p>            
          )}

        <div style={{ position: "relative" }}>
          {(menuItemData?.isLoading) && (
            <div className="tableLoader">
              <div
                style={{
                  top: "0",
                  alignItems: "flex-start",
                  paddingTop: "150px",
                }}
                className="spinnerLoad"
              >
                <Loading></Loading>
              </div>
            </div>
          )}

          {isDataLoaded && (
            <div style={{ position: "relative" }}>
              <div>
                {foods?.length <= 0 && (
                  <div style={{ borderBottom: "none" }}>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "17px",
                        padding: "100px 0 0",
                        width: " 96.9%",
                      }}
                    >
                      No Data Found
                    </div>
                  </div>
                )}
              </div>
              {foods?.map((food, index) => {
                const matchedCategory = food?.data?.find(
                  (item) =>
                    item?.foodCategory?.categoryName === food?.categoryName
                );
                const categoryId = matchedCategory
                  ? matchedCategory?.foodCategory?.categoryId
                  : null;
                return (
                  <div key={index} className="repeatSec">
                    <h3 className="titleWidth">
                      <input
                        type="checkbox"
                        id={categoryId}
                        checked={parentCheckboxes[categoryId] || false}
                        onChange={() => handleParentCheckboxChange(categoryId)}
                      />
                      <label htmlFor={categoryId}>{food?.categoryName}</label>
                    </h3>
                    <div className="dealflexRow">
                      {food?.data?.map((item, itemIndex) => (
                        <div key={itemIndex} className="boxCol">
                          <div className="flxr">
                            <input
                              type="checkbox"
                              id={item?.id}
                              checked={childCheckboxes[item?.id] || false}
                              onChange={() =>
                                handleChildCheckboxChange(item?.id)
                              }
                            />
                            <label htmlFor={item?.id}>
                              <p>{item.foodItemName}</p>
                              <p>${item?.price}</p>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {isSuccessPopupOpen && (
        <SuccessPopup />
      )}
    </Layout>
  );
};

export default SelectRelatedMenuItems;
