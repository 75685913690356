import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRestaurantLocationData } from "../../../../../store/selectors/dashboardSelectors";
import { userData } from "../../../../../store/selectors/authSelectors";
import { deleteRestaurantImages, getRestaurantDetail } from "../../../../../store/reducers/restaurantDetail";
import { deleteRestaurantLocationImages, getRestaurantLocationOwnerDetail } from "../../../../../store/reducers/restaurantLocationDetail";
import { constant } from "../../../../constants";

const ImageUploader = ({ companyWideImages, handleImage }) => {
  const [images, setImages] = useState(companyWideImages || []);
  const fileInputRef = useRef(null);
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantLocationId = restaurantDetailData?.restaurantId;
  const user = useSelector(userData);
  const dispatch = useDispatch();

  const handleAddImage = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const newImage = event.target.files[0];
    if (images.length < 5) {
      const updatedImages = [...images, newImage];
      setImages(updatedImages);
      handleImage(updatedImages);
    }
  };

  const handleRemoveImage = async (index) => {
    const dataOfImage = images[index];
    const updatedImages = images.filter((_, i) => i !== index);
    if (!dataOfImage?.imageId) {
      setImages(updatedImages);
      handleImage(updatedImages);
      return;
    }
      const restaurantImageId = dataOfImage?.imageId;
      const removeImageFunction = (user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin) ? () => dispatch(deleteRestaurantImages(restaurantImageId)) : () => dispatch(deleteRestaurantLocationImages({restaurantId: restaurantLocationId, restaurantImageId}));
        await removeImageFunction().then((response) => {
          switch (response?.meta?.requestStatus) {
            case constant.thunkStatus.FULFILLED:
              if (user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin) {
                dispatch(getRestaurantDetail());
              }
              else {
                dispatch(getRestaurantLocationOwnerDetail(restaurantLocationId));
              }
              setImages(updatedImages);
              handleImage(updatedImages);
              return;
      
            default:
              return;
          }
      });
  };

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100% !important" }}>
      {images.map((image, index) => (
        <div key={index} style={{ position: "relative", marginRight: "10px" }}>
          <img
            src={
              image instanceof File
                ? URL.createObjectURL(image)
                : image.imageFileUrl
            }
            style={{ width: "100px", height: "100px", marginRight: "5px" }}
            alt={`Image ${index + 1}`}
          />
          <div
            onClick={() => handleRemoveImage(index)}
            style={{
              background: "rgba(194, 35, 38, 1)",
              borderRadius: "50%",
              width: "25px",
              height: "25px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "-8px",
              right: "0px",
              cursor: "pointer",
            }}
          >
            <span style={{ color: "white", fontSize: "21px", position:"relative", marginTop:"-4px", }}>−</span>
          </div>
        </div>
      ))}
      {images.length === 5 ? (
        ""
      ) : (
        <div style={{ width: "100px" }}>
          <div
            onClick={handleAddImage}
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "rgba(125, 33, 57, 0.25)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <i
              style={{ color: "rgba(125, 33, 57, 1)" }}
              className="bx bx-plus"
            ></i>
          </div>
        </div>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept="image/*"
      />
    </div>
  );
};

export default ImageUploader;
