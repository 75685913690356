import React from "react";
import "../restaurant-locations.scss";
import { Formik, Form, Field } from "formik";
import Input from "../../../../components/input";
import * as Yup from "yup";
import Button from "../../../../components/button";
import Loading from "../../../../components/loading";
import toast from "react-hot-toast";
import { changePassword } from "../../../../../store/reducers/auth";
import { constant } from "../../../../constants";
import { authData } from "../../../../../store/selectors/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordModalHide } from "../../../../../store/reducers/modals";

const ChangePassWord = ({ closePopup }) => {
  const data = useSelector(authData);
  const dispatch = useDispatch();
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number,  and one special character (!@#$%^&*)",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmitData = async (values) => {
    const payload = {
      confirmPassword: values?.confirmPassword,
      newPassword: values?.newPassword,
    };
    dispatch(changePassword(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message ||
            "Password changed successfully"
          );
          dispatch(changePasswordModalHide())
          return;

        default:
          return;
      }
    });
  };


  return (
    <div className="popup changePassPop">
      <div className="popup-content">
        <h2>Change password</h2>
        <i onClick={closePopup} className="bx bx-x popClose"></i>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitData(values)}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <Field
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        placeholder="New Password"
                        component={Input}
                      />
                    </div>
                  </div>
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn"
                >
                  {data?.isLoading && <Loading></Loading>}
                  {isSubmitting ? "Submitting..." : "Done"}
                </Button>

              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassWord;
