import React from "react";
import RestaurantLocationsComponent from "../../../restaurant-locations/main-restaurant-location";

const RestaurantLocationDetail = ({loadingAdmin, searchQueryAdmin, filteredDataAdmin}) => {
  return (
    <div className="tab">
      <RestaurantLocationsComponent
        loadingAdmin={loadingAdmin}
        searchQueryAdmin={searchQueryAdmin}
        filteredDataAdmin={filteredDataAdmin}
      />
    </div>
  );
};

export default RestaurantLocationDetail;