import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDeleteRequest, fetchGetRequest, fetchMultipartPatchRequest, fetchMultipartPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { logoutUser } from "./authSlice";
import { deleteModalHide } from "./modals";

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  menuItems: null,
  menuItemsDeal: null,
};

export const getMenuItem = createAsyncThunk(
  "menuItem/getMenuItem",
  async ({ pageNum, pageSize, searchQuery, selectedCategories }, thunkAPI) => {
    try {
      const baseUrl = endpoints.dashboard.getMenuItems;
      const page = pageNum;
      const size = pageSize;
      const keywords = searchQuery;
      const foodCategoryIds = selectedCategories;
      let url = `${baseUrl}?page=${page}&size=${size}`;
      if (keywords !== "") {
        url += `&keywords=${keywords}`;
      }
      if (foodCategoryIds.length > 0) {
        url += `&foodCategoryIds=${foodCategoryIds.join("&foodCategoryIds=")}`;
      }
      const response = await fetchGetRequest(url);
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Menu Items");
      console.log("get MenuItem err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getAdminMenuItem = createAsyncThunk(
  "menuItem/getAdminMenuItem",
  async ({ restaurantId, pageNum, pageSize, searchQuery, selectedCategories }, thunkAPI) => {
    try {
      const baseUrl = endpoints.dashboard.getAdminMenuItem(restaurantId);
      const page = pageNum;
      const size = pageSize;
      const keywords = searchQuery;
      const foodCategoryIds = selectedCategories;
      let url = `${baseUrl}?page=${page}&size=${size}`;
      if (keywords !== "") {
        url += `&keywords=${keywords}`;
      }
      if (foodCategoryIds.length > 0) {
        url += `&foodCategoryIds=${foodCategoryIds.join("&foodCategoryIds=")}`;
      }
      const response = await fetchGetRequest(url);
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Menu Items");
      console.log("get MenuItem err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getMenuItemforDeal = createAsyncThunk(
  "menuItem/getMenuItemforDeal",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoints.dashboard.getMenuItems,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Menu Items");
      console.log("get MenuItem err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getMenuItemforAdminDeal = createAsyncThunk(
  "menuItem/getMenuItemforAdminDeal",
  async (restaurantId, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoints.dashboard.getAdminMenuItem(restaurantId),
      {
        params: {
          page: 1 || undefined,
          size: 999999 || undefined,
        },
      }
    );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Menu Items");
      console.log("get MenuItem err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createFoodItem = createAsyncThunk(
  "menuItem/createFoodItem",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchMultipartPostRequest(
        endpoints.dashboard.createFoodItem,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("create food item err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Food Item");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateFoodItem = createAsyncThunk(
  "menuItem/updateFoodItem",
  async ({formData, foodItemId}, thunkAPI) => {
    try {
      const response = await fetchMultipartPatchRequest(
        endpoints.dashboard.updateFoodItem(foodItemId),
        formData
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update food item err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Food Item");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createAdminFoodItem = createAsyncThunk(
  "menuItem/createAdminFoodItem",
  async ({formData}, thunkAPI) => {
    try {
      const response = await fetchMultipartPostRequest(
        endpoints.dashboard.createAdminFoodItem,
        formData
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("create food item err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Food Item");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateAdminFoodItem = createAsyncThunk(
  "menuItem/updateAdminFoodItem",
  async ({formData, foodId}, thunkAPI) => {
    try {
      const response = await fetchMultipartPatchRequest(
        endpoints.dashboard.updateAdminFoodItem(foodId),
        formData
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update food item err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Food Item");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const deleteMenuItem = createAsyncThunk(
  'menuItem/deleteMenuItem',
  async (foodItemId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteMenuItem(foodItemId),
      );
      toast.success(response?.data?.message || "Food Item deleted successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('menu item', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Food Item");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteAdminMenuItem = createAsyncThunk(
  'menuItem/deleteAdminMenuItem',
  async ({foodId}, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteAdminMenuItem(foodId),
      );
      toast.success(response?.data?.message || "Food Item deleted successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('menu item', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Food Item");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const { reducer, actions } = createSlice({
  name: "menuItem",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getMenuItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMenuItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.menuItems = action.payload.foods;
      })
      .addCase(getMenuItem.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAdminMenuItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminMenuItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.menuItems = action.payload.foods;
      })
      .addCase(getAdminMenuItem.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getMenuItemforDeal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMenuItemforDeal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.menuItemsDeal = action.payload.foods;
      })
      .addCase(getMenuItemforDeal.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getMenuItemforAdminDeal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMenuItemforAdminDeal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.menuItemsDeal = action.payload.foods;
      })
      .addCase(getMenuItemforAdminDeal.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createFoodItem.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createFoodItem.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createFoodItem.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateFoodItem.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateFoodItem.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateFoodItem.rejected, (state) => {
        state.isPopupLoading = false;
      })
      
      .addCase(createAdminFoodItem.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createAdminFoodItem.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createAdminFoodItem.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateAdminFoodItem.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateAdminFoodItem.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateAdminFoodItem.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(deleteMenuItem.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deleteMenuItem.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(deleteMenuItem.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(deleteAdminMenuItem.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deleteAdminMenuItem.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(deleteAdminMenuItem.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(logoutUser, (state) => {
        state.menuItems = null;
      }),
});

export default reducer;
export const {} = actions;
