import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGetRequest} from '../../libs/network';
import endpoints from '../../utils/endpoints';
import { logoutUser } from './authSlice';

const initialState = {
  isLoading: false,
  foodItemsById: null,
  error: null,
};

export const getFoodItemById = createAsyncThunk(
  'foodItemById/getFoodItemById',
  async (id, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getFoodItemById(id),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get food category err', JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const getAdminFoodItemById = createAsyncThunk(
  'foodItemById/getAdminFoodItemById',
  async ({foodId}, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAdminFoodItemById(foodId),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get food category err', JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const {reducer, actions} = createSlice({
  name: 'foodItemById',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getFoodItemById.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFoodItemById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodItemsById = action.payload.food;
        state.error = null;        
      })
      .addCase(getFoodItemById.rejected, state => {
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(getAdminFoodItemById.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAdminFoodItemById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodItemsById = action.payload.food;
        state.error = null;        
      })
      .addCase(getAdminFoodItemById.rejected, state => {
        state.isLoading = false;
        state.error = null;
      })

      .addCase(logoutUser, (state) => {
        state.foodItemsById = null;
      }),
});

export default reducer;
export const {} = actions;