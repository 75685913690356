import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGetRequest} from '../../libs/network';
import endpoints from '../../utils/endpoints';

const initialState = {
  isLoading: false,
  auth: null,
};

export const getAuthMe = createAsyncThunk(
  'authMe/getAuthMe',
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getMeData,
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get me data err', JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const {reducer, actions} = createSlice({
  name: 'authMe',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getAuthMe.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAuthMe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload.user;
        
      })
      .addCase(getAuthMe.rejected, state => {
        state.isLoading = false;
      }),
});

export default reducer;
export const {} = actions;