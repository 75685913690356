import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import { toast } from "react-hot-toast";
import Loading from "../../../components/loading";
import Select from "react-select";
import GoogleAutoComplete from "../../../components/google-auto-complete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { v4 as uuidv4 } from "uuid";
import "./restaurant.scss";
import { useDispatch, useSelector } from "react-redux";
import { createRestaurant } from "../../../../store/reducers/restaurant";
import { getRestaurantFoodCategoryForAdmin } from "../../../../store/reducers/foodCategory";
import { constant } from "../../../constants";
import { validatePhoneNumber } from "../../../components/validatePhoneNumber";
import { selectIsLocationModalOpen } from "../../../../store/selectors/modalSelectors";
import DeletePopup from "../../../components/delete-popup";
import { deleteLocationModalHide, deleteLocationModalOpen } from "../../../../store/reducers/modals";

const initialValues = {
  restaurantname: "",
  category: [],
  email: "",
  password: "",
  newlocations: [
    {
      id: uuidv4(),
      location: {
        city: "",
        country: "",
        latitude: null,
        longitude: null,
        state: "",
        streetAddress: "",
        zipCode: "",
      },
      phoneNo: "",
      phoneCountryCode: "",
    },
  ],
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #ced4da",
    borderRadius: "4px",
    minHeight: "38px",
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "8px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#ffc107",
    borderRadius: "20px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    color: "#ffc107",
    marginRight: "5px",
    marginLeft: "5px",
    marginTop: "5px",
    marginBottom: "5px",
    width: "20px",
    height: "20px",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};

const CreateRestaurantPopup = ({
  closePopup,
  getRestaurants,
  restaurantData,
}) => {
  const dispatch = useDispatch();
  const [foodCategories, setFoodCategories] = useState([]);
  const isDeletePopup = useSelector(selectIsLocationModalOpen);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleAddLocations = (values, push) => {
    push({
      id: uuidv4(),
      location: {
        city: "",
        country: "",
        latitude: null,
        longitude: null,
        state: "",
        streetAddress: "",
        zipCode: "",
      },
      phoneNo: "",
      phoneCountryCode: "",
    });
  };

  useEffect(() => {
    dispatch(getRestaurantFoodCategoryForAdmin()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setFoodCategories(
            response?.payload?.restaurantCategories?.map((item) => {
              return {
                value: item?.restaurantCategoryId,
                label: item?.restaurantCategoryName,
              };
            })
          );
          return;

        default:
          return;
      }
    });
  }, [dispatch]);

  const handleCreateRestaurant = async (values) => {
    if (values?.newlocations?.length <= 0) {
      return toast.error("Please add a location first");
    }
    const payload = {
      emailAddress: values?.email,
      locations: values?.newlocations.map((item) => {
        return {
          city: item?.location?.city,
          country: item?.location?.country,
          latitude: item?.location?.latitude,
          longitude: item?.location?.longitude,
          state: item?.location?.state,
          streetAddress: item?.location?.streetAddress,
          zipCode: item?.location?.zipCode,
          phoneNumber: item?.phoneNo,
          phoneCountryCode: item?.phoneCountryCode,
        };
      }),
      password: values?.password,
      restaurantName: values?.restaurantname,
      restaurantCategoryIds: values.category.map((item) => item.value),
    };

    dispatch(createRestaurant(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message || "Restaurant created successfully"
          );
          closePopup();
          getRestaurants();
          return;

        default:
          return;
      }
    });
  };

  const handleDeleteClick = (values, location, setFieldValue) => {
    dispatch(deleteLocationModalOpen());
    setSelectedLocation({values: values, location: location, setFieldValue: setFieldValue})
  };

  const removeLocation = () => {
    if (selectedLocation?.values && selectedLocation?.values?.newlocations) {
      const filteredLocation = selectedLocation?.values.newlocations.filter(
        (item) => item.id !==selectedLocation?.location.id
      );
      selectedLocation?.setFieldValue('newlocations', filteredLocation);
      dispatch(deleteLocationModalHide());
    }
  };

  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Add New Restaurant Account</h2>
        <i onClick={closePopup} className="bx bx-x popClose"></i>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            restaurantname: Yup.string().required(
              "Restaurant Name is required"
            ).max(255, "Restaurant Name cannot exceed 255 characters"),
            category: Yup.array()
              .of(
                Yup.object({
                  label: Yup.string().trim(),
                  value: Yup.string().trim(),
                })
              )
              .min(1, "Select at least one category"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email Address is required"),
            password: Yup.string()
              .required("Password is required")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
                "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number,  and one special character (!@#$%^&*)"
              ),
            newlocations: Yup.array().of(
              Yup.object().shape({
                id: Yup.string(),
                location: Yup.object().shape({
                  city: Yup.string().trim(),
                  country: Yup.string().trim(),
                  latitude: Yup.number().required("Latitude is required"),
                  longitude: Yup.number().required("Longitude is required"),
                  state: Yup.string().trim(),
                  streetAddress: Yup.string()
                    .required("Location is required")
                    .trim(),
                  zipCode: Yup.string().trim(),
                }),
                phoneCountryCode: Yup.string().required(
                  "Phone Country Code is required"
                ),
                phoneNo: Yup.string()
                .required("Phone Number is required")
                .test(
                  "is-valid-phone",
                  "Phone number is not valid",
                  function (value) {
                    const countryCode = this.parent.phoneCountryCode;
                    return validatePhoneNumber(value, countryCode);
                  }
                ),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting }) =>
            handleCreateRestaurant(values, setSubmitting)
          }
        >
          {({ isSubmitting, isValid, values, setFieldValue, handleBlur }) => {
            return (
              <Form>
                <div id="style-4" className="scrollbar">
                  <div className="force-overflow">
                    <div className="flexRow">
                      <div className="formCol">
                        <div className="form-group">
                          <label htmlFor="restaurantname">
                            Restaurant Name
                          </label>
                          <Field
                            type="text"
                            name="restaurantname"
                            id="restaurantname"
                            placeholder="Restaurant Name"
                            component={Input}
                          />
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email@example.com"
                            component={Input}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flexRow">
                      <div className="formCol">
                        <div className="form-group">
                          <label htmlFor="category">Restaurant Category</label>
                          <Select
                            options={foodCategories}
                            styles={customStyles}
                            isMulti={true}
                            placeholder="Select Category"
                            value={values.category}
                            isSearchable={true}
                            name="category"
                            className="catselectDropcustom"
                            onChange={(e) => {
                              console.log(e);
                              setFieldValue(
                                "category",
                                e.map((item) => {
                                  return {
                                    value: item.value,
                                    label: item.label,
                                  };
                                })
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          <div className="error-wrapper">
                            <ErrorMessage name="category" />
                          </div>
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form-group">
                          <label htmlFor="password">Create Password</label>
                          <Field
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            component={Input}
                          />
                        </div>
                      </div>
                    </div>

                    <FieldArray name="newlocations">
                      {({ push, remove }) => (
                        <div>
                          {values.newlocations.map((location, index) => (
                            <div key={location.id}>
                              <div className="flexRow">
                                <div className="formCol">
                                  <div className="form-group">
                                    <label htmlFor={`location_${index}`}>
                                      Location #{index + 1}
                                    </label>
                                    <GoogleAutoComplete
                                      onInputEmpty={() => {
                                        setFieldValue(
                                          `newlocations.${index}.location`,
                                          {
                                            city: "",
                                            country: "",
                                            latitude: null,
                                            longitude: null,
                                            state: "",
                                            streetAddress: "",
                                            zipCode: "",
                                          }
                                        );
                                      }}
                                      onSelect={({ place, address }) => {
                                        const addressComponents =
                                          place.address_components;
                                        let city,
                                          country,
                                          state,
                                          streetAddress,
                                          zipcode,
                                          latitude,
                                          longitude;
                                        streetAddress = address;
                                        addressComponents.forEach(
                                          (component) => {
                                            if (
                                              component.types.includes(
                                                "locality"
                                              )
                                            ) {
                                              city = component.long_name;
                                            }
                                            if (
                                              component.types.includes(
                                                "administrative_area_level_1"
                                              )
                                            ) {
                                              state = component.long_name;
                                            }
                                            if (
                                              component.types.includes(
                                                "country"
                                              )
                                            ) {
                                              country = component.long_name;
                                            }
                                            if (
                                              component.types.includes(
                                                "postal_code"
                                              )
                                            ) {
                                              zipcode = component.long_name;
                                            }
                                          }
                                        );

                                        latitude =
                                          place.geometry.location.lat();
                                        longitude =
                                          place.geometry.location.lng();

                                        setFieldValue(
                                          `newlocations.${index}.location`,
                                          {
                                            city,
                                            country,
                                            state,
                                            streetAddress,
                                            zipcode,
                                            latitude,
                                            longitude,
                                          }
                                        );
                                      }}
                                    />
                                    <div className="error-text">
                                      <ErrorMessage
                                        name={`newlocations.${index}.location.streetAddress`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    index === 0 ? "formCol" : "formCol flexRow"
                                  }
                                >
                                  <div className="form-group">
                                    <label htmlFor={`phoneNo_${index}`}>
                                      Phone Number
                                    </label>
                                    <PhoneInput
                                      country={"us"}
                                      value={values.phoneNo}
                                      onlyCountries={['us']}
                                      disableDropdown={true}
                                      onChange={(phone, data) => {
                                        setFieldValue(
                                          `newlocations.${index}.phoneNo`,
                                          phone.slice(data?.dialCode?.length)
                                        );
                                        setFieldValue(
                                          `newlocations.${index}.phoneCountryCode`,
                                          `${data?.dialCode}`
                                        );
                                      }}
                                    />
                                    <div className="error-text">
                                      <ErrorMessage
                                        name={`newlocations.${index}.phoneNo`}
                                      />
                                    </div>
                                  </div>
                                  {index > 0 && (
                                    <div
                                      className="delroundBtn"
                                      onClick={() => {
                                        handleDeleteClick(values, location, setFieldValue);
                                      }}
                                    >
                                      <i className="bx bxs-trash"></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="formCol">
                            <div className="form-group">
                              <label>
                                <a
                                  className="addText"
                                  href="#"
                                  onClick={() =>
                                    handleAddLocations(values, push)
                                  }
                                >
                                  <i className="bx bxs-plus-square"></i> Add
                                  Another Location
                                </a>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn"
                >
                  {restaurantData?.isPopupLoading && <Loading></Loading>}
                  {isSubmitting ? "Submitting..." : "Create"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteLocationModalHide())}
          handleDelete={removeLocation}
          title="Are you sure?"
          description="You want to delete this location? Deleting this restaurant location will delete all data associated with this location including menu items and deals. Do you wish to proceed?"
          buttonName="Remove"
        />
      )}
    </div>
    
  );
};
export default CreateRestaurantPopup;
