import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchGetRequest} from '../../libs/network';
import endpoints from '../../utils/endpoints';
import { logoutUser } from './authSlice';

const initialState = {
  isLoading: false,
  foodCategoriesById: null,
  error: null,
};

export const getFoodCategoryById = createAsyncThunk(
  'foodCategoryById/getFoodCategoryById',
  async (id, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getFoodCategoryById(id),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get food category err', JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const getAdminFoodCategoryById = createAsyncThunk(
  'foodCategoryById/getAdminFoodCategoryById',
  async (id, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAdminFoodCategoryById(id),
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('get food category err', JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const {reducer, actions} = createSlice({
  name: 'foodCategoryById',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getFoodCategoryById.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFoodCategoryById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodCategoriesById = action.payload;
        state.error = null;        
      })
      .addCase(getFoodCategoryById.rejected, state => {
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(getAdminFoodCategoryById.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAdminFoodCategoryById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodCategoriesById = action.payload;
        state.error = null;        
      })
      .addCase(getAdminFoodCategoryById.rejected, state => {
        state.isLoading = false;
        state.error = null;
      })
      
      .addCase(logoutUser, (state) => {
        state.foodCategoriesById = null;
      }),
});

export default reducer;
export const {} = actions;