import React from "react";
import Layout from "../../../layouts/dashboard-layout";
import { Link } from "react-router-dom";
import "./report.scss";

const reportData = [
  {
    id: 1,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 2,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 3,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 4,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 5,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 6,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 7,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
  {
    id: 8,
    title: "Weekly Report",
    date: "Week 1 - January 1 - January 7, 2024",
  },
];

const Report = () => {
  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth">Report</h2>
        </div>

        <div className="repeatSec2">
          <div className="flexRow">
            {reportData.map((report) => (
              <div key={report.id} className="boxCol">
                <Link to={`/dashboard/report/${report.id}`}>
                  <h3>{report.title}</h3>
                  <p>{report.date}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Report;
