export const selectIsAddDealModalOpen = (state) => state.modal.addDealModalIsOpen;
export const selectIsEditDealModalOpen = (state) => state.modal.editDealModalIsOpen;
export const selectIsSuccessModalOpen = (state) => state.modal.successModalIsOpen;
export const selectIsDeleteModalOpen = (state) => state.modal.deleteModalIsOpen;
export const selectIsDeleteRestaurantProfileModalOpen = (state) => state.modal.deleteRestaurantProfileModalIsOpen;
export const selectIsCreateAdminModalOpen = (state) => state.modal.createAdminModalIsOpen;
export const selectIsCreateRestaurantModalOpen = (state) => state.modal.createRestaurantModalIsOpen;
export const selectIsAddMenuItemModalOpen = (state) => state.modal.addMenuItemModalIsOpen;
export const selectIsEditMenuItemModalOpen = (state) => state.modal.editMenuItemModalIsOpen;
export const selectIsAddCategoryModalOpen = (state) => state.modal.addCategoryModalIsOpen;
export const selectIsEditCategoryModalOpen = (state) => state.modal.editCategoryModalIsOpen;
export const selectIsFilterCategoryModalOpen = (state) => state.modal.filterCategoryModalIsOpen;
export const selectIsAddRestaurantLocationModalOpen = (state) => state.modal.addRestaurantLocationModalIsOpen;
export const selectIsEditRestaurantLocationModalOpen = (state) => state.modal.editRestaurantLocationModalIsOpen;
export const selectIsEditRestaurantProfileModalOpen = (state) => state.modal.editRestaurantProfileModalIsOpen;
export const selectIschangePasswordModalOpen = (state) => state.modal.changePasswordModalIsOpen;
export const selectIsLocationModalOpen = (state) => state.modal.deleteLocationModalIsOpen;
export const selectIsCreateRestaurantCategoryModalOpen = (state) => state.modal.createRestaurantCategoryModalIsOpen;
export const selectIsEditRestaurantCategoryModalOpen = (state) => state.modal.editRestaurantCategoryModalIsOpen;
