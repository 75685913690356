import { Suspense } from "react";
import { MainLayout } from "./app/layouts/mainLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import { SuperAdminPrivateRoute } from "./app/guards/SuperAdminPrivateRoute";
import { PublicRoute } from "./app/guards/PublicRoute";
import { AuthPrivateRoute } from "./app/guards/AuthPrivateRoute";
import Welcome from "./app/pages/auth/welcome/welcome";
import Login from "./app/pages/auth/login/login";
import ForgotPassword from "./app/pages/auth/forgot-password/forgot-password";
import CreateNewPassword from "./app/pages/auth/create-new-password/create-new-password";
import VerifyEmail from "./app/pages/auth/verify-email/verify-email";
import Admin from "./app/pages/dashboard/admin/admin";
import Restaurant from "./app/pages/dashboard/restaurant/restaurant";
import Menu from "./app/pages/dashboard/menu/menu";
import Deal from "./app/pages/dashboard/deal/deal";
import Report from "./app/pages/dashboard/report/report";
import ReportPage from "./app/pages/dashboard/reportPage/reportPage";
import SelectRelatedMenuItems from "./app/pages/dashboard/deal/selectRelatedMenuItems";
import { AdminPrivateRoute } from "./app/guards/AdminPrivateRoute";
import { RestaurantPrivateRoute } from "./app/guards/RestaurantPrivateRoute";
import { AppPrivateRoute } from "./app/guards/AppPrivateRoute";
import { useSelector } from "react-redux";
import { selectToken, userData } from "./store/selectors/authSelectors";
import RestaurantLocations from "./app/pages/dashboard/restaurant-locations/restaurant-locations";
import ImportCSVFile from "./app/pages/dashboard/importCSVFile/importCSVFile";
import RestaurantDetails from "./app/pages/dashboard/details/restaurant-details/restaurant-details";
import RestaurantCategory from "./app/pages/dashboard/restaurant-category/restaurant-category";

const AppRoutes = () => {
  const token = useSelector(selectToken);
  const data = useSelector(userData);

  return (
    <MainLayout>
      <Suspense fallback={<h1>Loading</h1>}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Welcome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/*" element={<VerifyEmail />} />  
                    
          </Route>

          <Route element={<AuthPrivateRoute />}>
            <Route
              path="/create-new-password"
              element={<CreateNewPassword />}
            />
          </Route>

          {token &&
          !data?.userSettings?.needToChangePassword &&
          data?.userSettings?.isSuperAdmin &&
          data?.userType === "Administrator" ? (
            <Route element={<SuperAdminPrivateRoute />}>
              <Route path="/dashboard/admin" element={<Admin />} />
              <Route path="/dashboard/restaurant" element={<Restaurant />} />
              <Route path="/dashboard/import-csv-file" element={<ImportCSVFile />} />
              <Route path="/dashboard/restaurant-location-owner" element={<RestaurantDetails />} />
              <Route path="/dashboard/restaurant-category" element={<RestaurantCategory />} />
              <Route
                path="/dashboard/select-related-menu-items"
                element={<SelectRelatedMenuItems />}
              />
            </Route>
          ) : token &&
            !data?.userSettings?.needToChangePassword &&
            !data?.userSettings?.isSuperAdmin &&
            data?.userType === "Administrator" ? (
            <Route element={<AdminPrivateRoute />}>
              <Route path="/dashboard/restaurant" element={<Restaurant />} />
              <Route path="/dashboard/restaurant-location-owner" element={<RestaurantDetails />} />
              <Route path="/dashboard/restaurant-category" element={<RestaurantCategory />} />
              <Route
                path="/dashboard/select-related-menu-items"
                element={<SelectRelatedMenuItems />}
              />  
            </Route>
          ) : token &&
            !data?.userSettings?.needToChangePassword &&
            data?.userType === "Restaurant" ? (
            <Route element={<RestaurantPrivateRoute />}>
              <Route
                path="/dashboard/restaurant-locations"
                element={<RestaurantLocations />}
              />
              
              <Route path="/dashboard/menu" element={<Menu />} />
              <Route path="/dashboard/deals" element={<Deal />} />
              <Route
                path="/dashboard/select-related-menu-items"
                element={<SelectRelatedMenuItems />}
              />
            </Route>
          ) : null}

          <Route element={<AppPrivateRoute />}>
            <Route exact path="/dashboard/report" element={<Report />} />
            <Route path="/dashboard/report/:id" element={<ReportPage />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </MainLayout>
  );
};

export default AppRoutes;
