import React from "react";
import "./index.scss";
import Button from "../button";
import Loading from "../loading";

const DeletePopup = ({closePopup, popuploading, handleDelete, title, description, buttonName }) => {
  return (
      <div className="popup delresPopup">
        <div className="popup-content">
          <h2>{title}</h2>
              <p style={{ lineHeight: 1.7 }}>
                {description}
              </p>
              <div style={{ marginTop: 50 }} className="flxendBtn">
                <Button type="submit" className="delCancelBtnpop4" onClick={closePopup}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="delSubmitBtn2"
                  onClick={() => {handleDelete()}}
                >                  
                  {popuploading && <Loading></Loading>}
                  {buttonName}
                </Button>
              </div>
        </div>
      </div>
    )
};

export default DeletePopup;
