import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchDeleteRequest, fetchGetRequest, fetchPostRequest} from '../../libs/network';
import endpoints from '../../utils/endpoints';
import toast from 'react-hot-toast';
import { deleteModalHide } from './modals';

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  admins: null,
};

export const getAdmin = createAsyncThunk(
  'admin/getAdmin',
  async ({ pageNum, pageSize, searchQuery }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAdmin,
        {
          params: {
            page: pageNum || undefined,
            size: pageSize || undefined,
            keywords: searchQuery || undefined,
          },
        },
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Admins");
      console.log('get admin err', JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const createAdmin = createAsyncThunk(
  'admin/createAdmin',
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createAdmin,
        payload,
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log('create admin err', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Admin");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deleteAdmin = createAsyncThunk(
  'admin/deleteAdmin',
  async (administratorId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteAdmin(administratorId),
      );
      toast.success(response?.data?.message || "Admin deleted Successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('admin', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Admin");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const {reducer, actions} = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getAdmin.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.admins = action?.payload?.administrators;
        
      })
      .addCase(getAdmin.rejected, state => {
        state.isLoading = false;
      })

      .addCase(createAdmin.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(createAdmin.rejected, state => {
        state.isPopupLoading = false;
      })
      
      .addCase(deleteAdmin.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(deleteAdmin.rejected, state => {
        state.isPopupLoading = false;
      }),

});

export default reducer;
export const {} = actions;