import React from "react";
import Layout from "../../../layouts/dashboard-layout";
import RestaurantLocationsComponent from "./main-restaurant-location";

const RestaurantLocations = () => {
  return (
    <Layout>
      <RestaurantLocationsComponent />
    </Layout>
  );
};

export default RestaurantLocations;