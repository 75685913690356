import React from 'react';

const Input = ({ field, form: { touched, errors }, label, ...props }) => (
  <div className="form-control">
    <label htmlFor={field.name}>{label}</label>
    <input
      {...field}
      {...props}
      id={field.name}
      className={touched[field.name] && errors[field.name] ? 'error' : ''}
    />
    {touched[field.name] && errors[field.name] && (
      <div className="error-text">{errors[field.name]}</div>
    )}
  </div>
);

export default Input;
