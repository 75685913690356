import { parsePhoneNumberFromString } from 'libphonenumber-js';

function validatePhoneNumber(phoneNumber, countryCode) {
    if (!phoneNumber || !countryCode) return false;
    
    // Combine the country code and phone number to form the international format
    const fullPhoneNumber = `+${countryCode}${phoneNumber}`;
    const parsedNumber = parsePhoneNumberFromString(fullPhoneNumber);
  
    return parsedNumber && parsedNumber.isValid();
  }

  function formatPhoneNumber(phoneCountryCode, phoneNumber) {
    if (!phoneNumber) return "NA";
  
    // Ensure the phone number has 10 digits
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return `(+${phoneCountryCode}) (${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return phoneNumber;
  }

  export { validatePhoneNumber, formatPhoneNumber };