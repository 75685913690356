import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { getAdminFoodCategory, getFoodCategory } from "../../../../store/reducers/foodCategory";
import { constant } from "../../../constants";
import { selectRestaurantLocationData, selectfoodCategoryData } from "../../../../store/selectors/dashboardSelectors";
import { userData } from "../../../../store/selectors/authSelectors";

const FilterCategoryPopup = ({ isOpen, onClose, setSelectedCategories }) => {
  const [foodCategories, setFoodCategories] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const foodCategoryData = useSelector(selectfoodCategoryData);
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantId = restaurantDetailData?.restaurantId;
  const user = useSelector(userData);

  useEffect(() => {
    const fetchCategoryFunction =
      user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
        ? () => dispatch(getFoodCategory())
        : () => dispatch(getAdminFoodCategory(restaurantId));
    fetchCategoryFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const categories = response?.payload?.categories?.map((item) => ({
            value: item?.categoryId,
            label: item?.categoryName,
            checked: selectedCategoryIds.includes(item?.categoryId),
          }));
          setFoodCategories(categories);
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
  }, [dispatch, isOpen, restaurantId]);

  const handleCategoryChange = (categoryId) => {
    const updatedCategories = foodCategories.map((category) =>
      category.value === categoryId
        ? { ...category, checked: !category.checked }
        : category
    );
    setFoodCategories(updatedCategories);
    const selectedIds = updatedCategories
      .filter((category) => category.checked)
      .map((category) => category.value);
    setSelectedCategoryIds(selectedIds);
  };

  const handleFilter = () => {
    setSelectedCategories(selectedCategoryIds);
    onClose();
  };

  const handleClearFilter = () => {
    const clearedCategories = foodCategories.map((category) => ({
      ...category,
      checked: false,
    }));
    setFoodCategories(clearedCategories);
    setSelectedCategoryIds([]);
  };

  return (
    isOpen && (
      <div className="popup filterPopup">
        <div className="popup-content editCategorypop">
          <h2>Filter by</h2>
          <i style={{ top: "20px" }} className="bx bx-x" onClick={onClose}></i>
          {foodCategoryData?.isLoading ? (
            <div style={{ position: "relative" }}>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="tableLoader"
              >
                <Loading />
              </div>
            </div>
          ) : (
            
                <div className="filterList">
                  <h3>Cuisine</h3>
                  <div style={{float:"none", padding:"10px 0", height:"200"}} id="style-4" className="scrollbar">
                    <div className="force-overflow">
                  {isDataLoaded && (
                    <div style={{ position: "relative" }}>
                      <div>
                        {foodCategories?.length <= 0 && (
                          <div style={{ borderBottom: "none" }}>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "17px",
                                width: "96.9%",
                                color: "red",
                              }}
                            >
                              No Category Found
                            </div>
                          </div>
                        )}
                      </div>
                      <ul>
                        {foodCategories?.map((category, index) => (
                          <li key={category.value}>
                            <div className="filterformGroup">
                              <input
                                checked={category.checked}
                                onChange={() =>
                                  handleCategoryChange(category.value)
                                }
                                type="checkbox"
                                id={index}
                              />
                              <label htmlFor={index}>{category.label}</label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="flxendBtn">
                <button
                  className="dealCancleBtn"
                  onClick={handleClearFilter}
                >
                  Clear Filter
                </button>
                <button className="dealbtn" onClick={handleFilter}>
                  Filter
                </button>
              </div>
            </div>

          )}

        </div>

      </div>
    )
  );
};

export default FilterCategoryPopup;
