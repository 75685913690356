import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Logo from "../../../../assets/images/logo.png";
import "./create-new-password.scss";
import { storedData } from "../../../../store/reducers/authSlice";
import Loading from "../../../components/loading";
import { constant } from "../../../constants";
import { changePassword, logout } from "../../../../store/reducers/auth";
import { authData } from "../../../../store/selectors/authSelectors";
import { getAuthMe } from "../../../../store/reducers/authMe";

const CreateNewPassword = () => {
  const dispatch = useDispatch();
  const data = useSelector(authData);

  const handleLogout = async () => {
    dispatch(logout()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          return;
  
        default:
          return;
      }
    });
  };

  const handleSubmitData = async (values) => {
    const payload = {
        confirmPassword: values?.confirmPassword,
        newPassword: values?.newPassword,
    };
    dispatch(changePassword(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message ||
            "Password changed successfully"
          );
          dispatch(getAuthMe()).then((response) => {
            switch (response?.meta?.requestStatus) {
              case constant.thunkStatus.FULFILLED:
                dispatch(storedData(response?.payload?.user));
                return;
      
              default:
                return;
            }
          });
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="createNewPass-wrapper">
      <div className="createNewPass-row">
        <div className="left-section">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-section">
          <div className="createNewPass-container">
            <h2>Create a New Password</h2>
            <p>
              Your new password must be different from your previous password.
            </p>
            <Formik
              initialValues={{ newPassword: "", confirmPassword: "" }}
              validationSchema={Yup.object().shape({
                newPassword: Yup.string()
                  .required("New Password is required")
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
                    "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number,  and one special character (!@#$%^&*)"
                  ),
                confirmPassword: Yup.string()
                  .required("Confirm Password is required")
                  .oneOf(
                    [Yup.ref("newPassword"), null],
                    "Passwords must match"
                  ),
              })}
              onSubmit={(values, { setSubmitting }) =>
                handleSubmitData(values, setSubmitting)
              }
            >
              {({ isSubmitting, isValid }) => {
                return (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <Field
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        placeholder="New Password"
                        component={Input}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="confirmPassword">
                        Confirm New Password
                      </label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm New Password"
                        component={Input}
                      />
                    </div>
                    <div className="createNewPass-buttonSec">
                      <Button
                        type="button"
                        onClick={handleLogout}
                        className="createNewPassback-button"
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        className="createNewPass-button"
                      >
                        {data?.isLoading && <Loading></Loading>}
                        {isSubmitting ? "Submitting..." : "Save"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
