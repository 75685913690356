import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByPlaceId } from "react-places-autocomplete";

function GoogleAutoComplete({ searchQuery = "", onSelect, onInputEmpty }) {
  const [address, setAddress] = useState(searchQuery);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleChange = (address) => {
    setAddress(address);
    setShowSuggestions(true);
    if (address === "") {
      onInputEmpty();
    }
  };

  const handleSelect = (placeId, address) => {
    setAddress(address);
    setShowSuggestions(false);
    geocodeByPlaceId(placeId)
      .then((place) => {
        onSelect({ place: place?.[0] || {}, address: address });
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={(val) => handleSelect(val, address)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-control">
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            />
            {showSuggestions && (
            <div className="autocomplete-dropdown-container autodrop">
              {loading && <div>Loading...</div>}

              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? {
                      backgroundColor: "#32807f",
                      cursor: "pointer",
                      padding: "5px",
                      color: "#fff",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: "5px",
                      color: "#3E3E3E",
                    };
                const suggestionProps = getSuggestionItemProps(suggestion);
                const { key, ...restProps } = suggestionProps;
                return (
                  <div
                    key={index}
                    {...restProps}
                    className={className}
                    style={style}
                    onClick={() =>
                      handleSelect(suggestion.placeId, suggestion.description)
                    }
                  >
                    <span>
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

export default GoogleAutoComplete;
