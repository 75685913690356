import React, { useEffect, useState } from "react";
import AddMenuItemPopup from "./addMenuItemPopup";
import CategoryPopup from "./categoryPopup";
import { Link } from "react-router-dom";
import FilterIcon from "../../../../assets/images/filter-icon.svg";
import Loading from "../../../components/loading";
import FilterCategoryPopup from "./filterCategoryPopup";
import DeletePopup from "../../../components/delete-popup";
import { useDispatch, useSelector } from "react-redux";
import { deleteMenuItem, getMenuItem } from "../../../../store/reducers/menu";
import { selectMenuItemsData, selectRestaurantLocationData } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import { selectIsAddMenuItemModalOpen, selectIsDeleteModalOpen, selectIsEditCategoryModalOpen, selectIsEditMenuItemModalOpen, selectIsFilterCategoryModalOpen } from "../../../../store/selectors/modalSelectors";
import { addMenuItemModalHide, addMenuItemModalOpen, deleteModalHide, deleteModalOpen, editCategoryModalHide, editCategoryModalOpen, editMenuItemModalHide, editMenuItemModalOpen, filterCategoryModalHide,  filterCategoryModalOpen } from "../../../../store/reducers/modals";
import { deleteAdminMenuItem, getAdminMenuItem } from "../../../../store/reducers/menu";
import { userData } from "../../../../store/selectors/authSelectors";
import "./menu.scss";
import "./menu.css";

const MenuComponent = ({ searchQueryAdmin, isEditModeAdmin }) => {
  const dispatch = useDispatch();
  const user = useSelector(userData);
  const menuItemData = useSelector(selectMenuItemsData);
  const isAddMenuItemPopupOpen = useSelector(selectIsAddMenuItemModalOpen);
  const isEditMenuItemPopupOpen = useSelector(selectIsEditMenuItemModalOpen);
  const isEditCategoryPopupOpen = useSelector(selectIsEditCategoryModalOpen);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const isfilterCategoryPopupOpen = useSelector(selectIsFilterCategoryModalOpen);
  const [isEditMode, setIsEditMode] = useState(false);
  const [prevEditMode, setPrevEditMode] = useState(false);
  const [foods, setFoods] = useState(menuItemData?.menuItems);
  const [selectedFoodId, setSelectedFoodId] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedFoodItemId, setSelectedFoodItemId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPagesize] = useState(999999);
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantId = restaurantDetailData?.restaurantId; 
  const userIsRestaurant = user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin;
  const currentEditMode = userIsRestaurant ? isEditMode : isEditModeAdmin;

  const closeAddMenuItemPopup = () => {
    dispatch(addMenuItemModalHide());
    dispatch(editMenuItemModalHide());
  };
  const openEditMenuItemPopup = (foodItemId) => {
    dispatch(editMenuItemModalOpen());
    setSelectedFoodItemId(foodItemId);
  };
  const closeEditMenuItemPopup = () => {
    dispatch(editMenuItemModalHide());
    dispatch(addMenuItemModalHide());
  };
  const openEditCategoryPopup = (categoryId) => {
    dispatch(editCategoryModalOpen());
    setSelectedCategoryId(categoryId);
  };

  const toggleEditMode = () => {
    if (!(currentEditMode)) {
      setPrevEditMode(currentEditMode);
    }
    setIsEditMode(!(currentEditMode));
  };
  const toggleToPrevEditMode = () => {
    setIsEditMode(prevEditMode);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getFoodsData = async (page = pageNum) => {
    const fetchMenuItems = userIsRestaurant ? () => dispatch(getMenuItem({pageNum: page + 1, pageSize, searchQuery, selectedCategories}))
        : () => dispatch(getAdminMenuItem({restaurantId, pageNum: page + 1, pageSize, searchQuery: searchQueryAdmin, selectedCategories}));

    fetchMenuItems().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const grouped = response?.payload?.foods?.reduce((acc, item) => {
            if (!acc[item.foodCategory.categoryName]) {
              acc[item.foodCategory.categoryName] = {
                categoryName: item.foodCategory.categoryName,
                data: [],
              };
            }
            acc[item.foodCategory.categoryName].data.push(item);
            return acc;
          }, {});
          const sortedCategories = Object.values(grouped).sort((a, b) =>
            a.categoryName.localeCompare(b.categoryName)
          );
          setFoods(sortedCategories);
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
    dispatch(addMenuItemModalHide());
    dispatch(deleteModalHide());
    dispatch(editCategoryModalHide());
    dispatch(editMenuItemModalHide());
    dispatch(filterCategoryModalHide());
    return () => {
      dispatch(addMenuItemModalHide());
      dispatch(deleteModalHide());
      dispatch(editCategoryModalHide());
      dispatch(editMenuItemModalHide());
      dispatch(filterCategoryModalHide());
    };
  };

  useEffect(() => {
    getFoodsData();
  }, [dispatch, searchQuery, searchQueryAdmin, selectedCategories]);

  const handleDeleteClick = (adminId) => {
    setSelectedFoodId(adminId);
    dispatch(deleteModalOpen());
  };

  const removeAccess = async (foodItemId) => {
    const deleteMenuItemFunction = userIsRestaurant ? () => dispatch(deleteMenuItem(foodItemId)) : () => dispatch(deleteAdminMenuItem({ foodId: foodItemId }));
    deleteMenuItemFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          getFoodsData();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className={(userIsRestaurant) ? "homeContent prcon20" : undefined}>
      {userIsRestaurant && (
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Manage Your Menu</h2>
          <div className="iconSce">
            <div className="filterSec">
              <Link>
                <img
                  src={FilterIcon}
                  onClick={() => dispatch(filterCategoryModalOpen())}
                  alt="FilterIcon"
                />
              </Link>
            </div>
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div style={{ display: "flex" }}>
              {currentEditMode ? (
                <a className="doneBtn" onClick={toggleToPrevEditMode}>
                  Done
                </a>
              ) : (
                <i className="bx bxs-pencil" onClick={toggleEditMode}></i>
              )}
              <i
                onClick={() => dispatch(addMenuItemModalOpen())}
                className="bx bx-plus"
              ></i>
            </div>
          </div>
        </div>
      )}

      <div style={{ position: "relative" }}>
        {menuItemData?.isLoading && (
          <div className="tableLoader">
            <div
              style={{
                top: "0",
                alignItems: "flex-start",
                paddingTop: "150px",
              }}
              className="spinnerLoad"
            >
              <Loading></Loading>
            </div>
          </div>
        )}

        {isDataLoaded && (
          <div style={{ position: "relative" }}>
            <div>
              {foods?.length <= 0 && (
                <div style={{ borderBottom: "none" }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      padding: "100px 0 0",
                      width: " 96.9%",
                    }}
                  >
                    No Data Found
                  </div>
                </div>
              )}
            </div>
            {foods?.map((food, index) => {
              const matchedCategory = food.data.find(
                (item) => item.foodCategory.categoryName === food.categoryName
              );
              const categoryId = matchedCategory
                ? matchedCategory.foodCategory.categoryId
                : null;
              return (
                <div key={index} className={(userIsRestaurant) ? "repeatSec" : "repeatSec repeatSecmargin2"}>
                  <h3  style={(userIsRestaurant) ? { width: "70%" } : undefined} className={(user.userType !== "Restaurant") ? "titleWidth" : undefined}>
                    {food?.categoryName}
                    {(currentEditMode) && (
                      <i
                        className="bx bxs-pencil"
                        onClick={() => openEditCategoryPopup(categoryId)}
                      ></i>
                    )}
                  </h3>
                  <div className={(userIsRestaurant) ? "flexRow" : "flexRow flexRow2"}>
                    {food?.data?.map((item, itemIndex) => (
                      <div key={itemIndex} className={(userIsRestaurant) ? "boxCol" : "boxCol boxCol3"}>
                        {currentEditMode ? (
                          <div className="flexSec">
                            <div
                              style={(userIsRestaurant) ? { paddingLeft: "20px", width: "55%" } : {width:"65%"}}
                              className=""
                            >
                              <p>{item?.foodItemName}</p>
                              <p>${item?.price}</p>
                            </div>{" "}
                            <div className="iconSec" style={(user.userType !== "Restaurant") ? { paddingRight:"0" } : undefined}>
                              <i
                                className="bx bxs-trash"
                                onClick={() => handleDeleteClick(item?.id)}
                              ></i>
                              <i
                                className="bx bxs-pencil"
                                onClick={() => openEditMenuItemPopup(item?.id)}
                              ></i>
                            </div>
                          </div>
                        ) : (
                          <div style={(userIsRestaurant) ? { padding: "0 20px" } : undefined} className="">
                            <p>{item.foodItemName}</p>
                            <p>${item?.price}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => removeAccess(selectedFoodId)}
          popuploading={menuItemData?.isPopupLoading}
          title="Delete Confirmation"
          description="Are you sure you want to Delete the Food Item?"
          buttonName="Yes, Delete!"
        />
      )}
      <AddMenuItemPopup
        isOpen={isAddMenuItemPopupOpen || isEditMenuItemPopupOpen}
        onClose={currentEditMode ? closeEditMenuItemPopup : closeAddMenuItemPopup}
        isEditMode={isEditMenuItemPopupOpen}
        getFoodsData={getFoodsData}
        foodItemId={selectedFoodItemId}
      />
      <CategoryPopup
        isOpen={isEditCategoryPopupOpen}
        onClose={() => dispatch(editCategoryModalHide())}
        isEditMode={isEditCategoryPopupOpen}
        categoryId={selectedCategoryId}
        getFoodsData={getFoodsData}
      />
      <FilterCategoryPopup
        isOpen={isfilterCategoryPopupOpen}
        onClose={() => dispatch(filterCategoryModalHide())}
        setSelectedCategories={setSelectedCategories}
      />
    </div>
  );
};

export default MenuComponent;
