import React from "react";
import MenuComponent from "../../../menu/mainMenu";

const MenuAdmin = ({ searchQueryAdmin, isEditModeAdmin }) => {
  return (
    <div style={{ paddingTop: "1rem" }} className="tab">
      <MenuComponent
        searchQueryAdmin={searchQueryAdmin}
        isEditModeAdmin={isEditModeAdmin}
      />
    </div>
  );
};

export default MenuAdmin;
