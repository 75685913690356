import React from "react";
import Layout from "../../../layouts/dashboard-layout";
import "./reportPage.scss";
import AddReportIcon from "../../../../assets/images/add-report-icon.svg";

const ReportPage = () => {
  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2>Weekly Report - Week 1: January 1 - January 7, 2024</h2>
          <div className="iconSce">
           <div className="addIcon"><img src={AddReportIcon} alt="Icon" /></div>
            {/* <i className='bx bxs-file-export'></i> */}
          </div>
        </div>

        <div className="repeatSec">
          <div className="comingSec">
            Report goes here
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportPage;
