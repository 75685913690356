import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import "./deal.scss";
import { dealsData } from "../../../../store/reducers/deal";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const AddDealPopup = ({ onClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState("");
  const today = format(new Date(), "yyyy-MM-dd");

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.id);
  };

  const handleAddDeal = async (values) => {
    let updatedValues = { ...values };
    if (isChecked === "two") {
      updatedValues = { ...values, claimPerUser: 1 };
    } else if (isChecked === "one") {
      updatedValues = { ...values };
      delete updatedValues.claimPerUser;
    } else {
      toast.error("Please select a claim count per user option.");
      return;
    }
    dispatch(dealsData(updatedValues));
    navigate("/dashboard/select-related-menu-items");
  };

  return (
    <div className="popup">
      <div className="popup-content2">
        <h2>Add a Deal</h2>
        <Formik
          initialValues={{
            dealTitle: "",
            startDate: "",
            endDate: "",
          }}
          validationSchema={Yup.object().shape({
            dealTitle: Yup.string().required("Deal Title is required").max(150, "Deal Title cannot exceed 150 characters"),
            startDate: Yup.date()
              .min(today, "Start Date cannot be in the past")
              .required("Start Date is required"),
            endDate: Yup.date()
             .min(Yup.ref("startDate"), "End Date cannot be earlier than Start Date"),
          })}
          onSubmit={(values, { setSubmitting }) =>
            handleAddDeal(values, setSubmitting)
          }
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="dealTitle">Deal Title*</label>
                      <Field
                        type="text"
                        name="dealTitle"
                        id="dealTitle"
                        placeholder="Deal Title"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="startDate">Start Date*</label>
                      <Field
                        type="date"
                        name="startDate"
                        id="startDate"
                        min={today}
                        onFocus={(e) => e.target.showPicker()}
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="endDate">End Date</label>
                      <Field
                        type="date"
                        name="endDate"
                        id="endDate"
                        min={today}
                        onFocus={(e) => e.target.showPicker()}
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label>Claim Count Per User</label>
                      <div className="claimCheckRow">
                        <div className="claimCheckSection">
                          <input id="one" type="checkbox" checked={isChecked === "one"} onChange={handleCheckboxChange} />
                          <label htmlFor="one">Unlimited Use</label>
                        </div>
                        <div className="claimCheckSection">
                          <input id="two" type="checkbox" checked={isChecked === "two"} onChange={handleCheckboxChange} />
                          <label htmlFor="two">One-Time Use</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flxendBtn">
                  <Button
                    type="submit"
                    className="dealCancleBtn"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="dealbtn"
                  >
                    Select Related Menu Items
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddDealPopup;
