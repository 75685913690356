import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest, fetchPatchRequest, fetchPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { logoutUser } from "./authSlice";

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  foodCategories: null,
  foodTagCategories: null,
};

export const getFoodCategory = createAsyncThunk(
  "foodCategory/getFoodCategory",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getFoodCategories,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Categories");
      console.log("get category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getAdminFoodCategory = createAsyncThunk(
  "foodCategory/getAdminFoodCategory",
  async (restaurantId, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAdminFoodCategory(restaurantId),
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Categories");
      console.log("get category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getRestaurantFoodCategoryForAdmin = createAsyncThunk(
  "foodCategory/getRestaurantFoodCategoryForAdmin",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getRestaurantFoodCategoryForAdmin,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Categories");
      console.log("get category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getFoodCategoryForRestaurant = createAsyncThunk(
  "foodCategory/getFoodCategoryForRestaurant",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getFoodCategoryForRestaurant,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Categories");
      console.log("get category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createFoodCategory = createAsyncThunk(
  "foodCategory/createFoodCategory",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createFoodCategory,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("create food category err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createAdminFoodCategory = createAsyncThunk(
  "foodCategory/createAdminFoodCategory",
  async ({payload, restaurantId}, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createAdminFoodCategory(restaurantId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("create food category err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateFoodCategory = createAsyncThunk(
  "foodCategory/updateFoodCategory",
  async ({payload, categoryId}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.updateFoodCategory(categoryId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update food category err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateAdminFoodCategory = createAsyncThunk(
  "foodCategory/updateAdminFoodCategory",
  async ({payload, categoryId}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.updateAdminFoodCategory(categoryId),
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("update food category err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getAdminFoodTagCategory = createAsyncThunk(
  "foodCategory/getAdminFoodTagCategory",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAdminFoodTagCategory,
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Food Tag Categories");
      console.log("get tag category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getFoodTagCategory = createAsyncThunk(
  "foodCategory/getFoodTagCategory",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getFoodTagCategory,
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Food Tag Categories");
      console.log("get tag category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "foodCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getFoodCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFoodCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodCategories = action.payload.categories;
      })
      .addCase(getFoodCategory.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAdminFoodCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminFoodCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodCategories = action.payload.categories;
      })
      .addCase(getAdminFoodCategory.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getRestaurantFoodCategoryForAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurantFoodCategoryForAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodCategories = action?.payload?.restaurantCategories;
      })
      .addCase(getRestaurantFoodCategoryForAdmin.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getFoodCategoryForRestaurant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFoodCategoryForRestaurant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodCategories = action?.payload?.restaurantCategories;
      })
      .addCase(getFoodCategoryForRestaurant.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createFoodCategory.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createFoodCategory.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createFoodCategory.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(createAdminFoodCategory.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createAdminFoodCategory.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createAdminFoodCategory.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(updateFoodCategory.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateFoodCategory.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateFoodCategory.rejected, (state) => {
        state.isPopupLoading = false;
      })
      
      .addCase(updateAdminFoodCategory.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(updateAdminFoodCategory.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(updateAdminFoodCategory.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(getAdminFoodTagCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminFoodTagCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodTagCategories = action.payload?.foodTagCategories;
      })
      .addCase(getAdminFoodTagCategory.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getFoodTagCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFoodTagCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodTagCategories = action.payload?.foodTagCategories;
      })
      .addCase(getFoodTagCategory.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(logoutUser, (state) => {
        state.foodCategories = null;
      }),
});

export default reducer;
export const {} = actions;
