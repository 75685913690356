import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest, fetchPatchRequest, fetchPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { deleteModalHide } from "./modals";

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  restaurants: null,
};

export const getRestaurant = createAsyncThunk(
  "restaurant/getRestaurant",
  async ({ searchQuery, order_by, order_type }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getRestaurant,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
            keywords: searchQuery || undefined,
            order_by: order_by || undefined,
            order_type: order_type || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Restaurants");
      console.log("get restaurant err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createRestaurant = createAsyncThunk(
  "restaurant/createRestaurant",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createRestaurant,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("create restaurant err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to create Restaurant");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const removeAccessRestaurant = createAsyncThunk(
  "restaurant/removeAccessRestaurant",
  async ({payload, restaurant_id}, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.removeAccessRestaurant(restaurant_id),
        payload
      );
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log("restaurant err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed, Please try again Later");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "restaurant",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getRestaurant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restaurants = action?.payload?.restaurants;
      })
      .addCase(getRestaurant.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createRestaurant.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createRestaurant.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createRestaurant.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(removeAccessRestaurant.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(removeAccessRestaurant.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(removeAccessRestaurant.rejected, (state) => {
        state.isPopupLoading = false;
      }),
});

export default reducer;
export const {} = actions;
