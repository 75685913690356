import React from "react";
import Layout from "../../../layouts/dashboard-layout";
import MenuComponent from "./mainMenu";

const Menu = () => {
  return (
    <Layout>
      <MenuComponent />
    </Layout>
  );
};

export default Menu;
