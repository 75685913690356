import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectToken, userData } from "../../store/selectors/authSelectors";

export const AdminPrivateRoute = ({ children }) => {
  const token = useSelector(selectToken);
  const data = useSelector(userData);
  if (!token) {
    return <Navigate to="/" replace />;
  } else if (token && data?.userSettings?.needToChangePassword) {
    return <Navigate to="/create-new-password" replace />;
  } else if (
    token &&
    !data?.userSettings?.needToChangePassword &&
    data?.userSettings?.isSuperAdmin &&
    data?.userType === "Administrator"
  ) {
    return <Navigate to="/dashboard/admin" replace />;
  } else if (
    token &&
    !data?.userSettings?.needToChangePassword &&
    // !data?.userSettings?.isSuperAdmin &&
    data?.userType === "Restaurant"
  ) {
    return <Navigate to="/dashboard/restaurant-locations" replace />;
  }

  return children ? children : <Outlet />;
};
