import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Logo from "../../../../assets/images/logo.png";
import { authData, userType } from "../../../../store/selectors/authSelectors";
import Loading from "../../../components/loading";
import "./forgot-password.scss";
import { constant } from "../../../constants";
import { forgotPassword } from "../../../../store/reducers/auth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authForgotPasswordData = useSelector(authData);
  const user = useSelector(userType);

  const handleForgotPassword = async (values) => {
    const payload = {
      emailAddress: values?.email,
      userType: user?.userTypeValue,
    };

    dispatch(forgotPassword(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const data = response?.payload;
          toast.success(data?.message);
          navigate(`/reset-password?email=${values?.email}`);
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="forgot-wrapper">
      <div className="forgot-row">
        <div className="left-section">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-section">
          <div className="forgot-container">
            <h2>Forgot Password?</h2>
            <p>
              Please enter your email address to receive a verification code.
            </p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email")
                  .required("Email Address is required"),
              })}
              onSubmit={(values, { setSubmitting }) =>
                handleForgotPassword(values, setSubmitting)
              }
            >
              {({ isSubmitting, isValid }) => {
                return (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email@example.com"
                        component={Input}
                      />
                    </div>
                    <div className="forgot-buttonSec">
                      <a type="button" href="/" className="back-button">
                        Back
                      </a>
                      <Button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        className="forgot-button"
                      >
                        {authForgotPasswordData?.isResendLoading && <Loading></Loading>}
                        {isSubmitting ? "Sending..." : "Get Code"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
