import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Logo from "../../../../assets/images/logo.png";
import { authData, storedTimer, userType } from "../../../../store/selectors/authSelectors";
import Loading from "../../../components/loading";
import { storeTimer } from "../../../../store/reducers/timerSlice";
import "./verify-email.scss";
import { constant } from "../../../constants";
import { forgotPassword, resetPassword } from "../../../../store/reducers/auth";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const authResetPasswordData = useSelector(authData);
  const user = useSelector(userType);

  const dispatch = useDispatch();
  const storeTime = useSelector(storedTimer);
  const [timer, setTimer] = useState(storeTime);
  const [resendDisabled, setResendDisabled] = useState(false);

  const handleResendCode = async () => {
    if (resendDisabled) {
      return;
    }
    const payload = {
      emailAddress: email,
      userType: user?.userTypeValue,
    };

    dispatch(forgotPassword(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const data = response?.payload;
          toast.success(data?.message);
          startTimer();
          return;

        default:
          return;
      }
    });
  };

  const startTimer = () => {
    setTimer(90);
    dispatch(storeTimer(90));
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer - 1;
        dispatch(storeTimer(newTimer));
        return newTimer;
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      dispatch(storeTimer(0));
    }, 90000);
  };

  useEffect(() => {
    if (storeTime > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          dispatch(storeTimer(newTimer));
          return newTimer;
        });
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setTimer(0);
    }
  }, [dispatch, storeTime]);

  useEffect(() => {
    if (storeTime > 0) {
      setResendDisabled(true);
    } else {
      setResendDisabled(false);
    }
  }, [storeTime]);

  const handleResetPassword = async (values) => {
    const payload = {
      confirmPassword: values?.confirmPassword,
      emailAddress: email,
      newPassword: values?.newPassword,
      resetCode: values?.code,
      userType: user?.userTypeValue,
    };

    dispatch(resetPassword(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const data = response?.payload;
          toast.success(data?.message);
          setTimer(0);
          navigate(`/`);
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="verify-wrapper">
      <div className="verify-row">
        <div className="left-section">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-section">
          <div className="verify-container">
            <h2>Create a New Password</h2>
            <div className="text-left">
              <h3>Verify Email</h3>
              <p>Enter the 6 digit code that was sent to {email}</p>
            </div>
            <Formik
              initialValues={{ code: "", newPassword: "", confirmPassword: "" }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .required("Verification Code is required")
                  .matches(
                    /^[0-9]{6}$/,
                    "Verification Code must be exactly 6 digits long and contain only numbers"
                  ),
                newPassword: Yup.string()
                  .required("New Password is required")
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
                    "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number,  and one special character (!@#$%^&*)"
                  ),
                confirmPassword: Yup.string()
                  .required("Confirm Password is required")
                  .oneOf(
                    [Yup.ref("newPassword"), null],
                    "Passwords must match"
                  ),
              })}
              onSubmit={(values, { setSubmitting }) =>
                handleResetPassword(values, setSubmitting)
              }
            >
              {({ isSubmitting, isValid, errors }) => {
                return (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="code">Verification Code</label>
                      <Field
                        type="code"
                        name="code"
                        id="code"
                        component={Input}
                      />
                    </div>

                    {timer > 0 ? (
                      <div
                        className="text-center"
                        style={{ position: "relative" }}
                      >
                        <a
                          className="resendText"
                          onClick={handleResendCode}
                          disabled={resendDisabled}
                        >
                          Resend Code again in {timer} seconds
                        </a>
                        {authResetPasswordData?.isResendLoading && <Loading></Loading>}
                      </div>
                    ) : (
                      <div
                        className="text-center"
                        style={{ position: "relative" }}
                      >
                        <a className="resendText" onClick={handleResendCode}>
                          Resend Code
                        </a>
                        {authResetPasswordData?.isResendLoading && <Loading></Loading>}
                      </div>
                    )}
                    <div className="text-left">
                      <h3>Set New Password</h3>
                      <p>
                        Your new password must be different from your previous
                        password.
                      </p>
                    </div>

                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <Field
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        placeholder="New Password"
                        component={Input}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="confirmPassword">
                        Confirm New Password
                      </label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm New Password"
                        component={Input}
                      />
                    </div>
                    <div className="verify-buttonSec">
                      <Button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        className="verify-button"
                      >
                        {authResetPasswordData?.isLoading && <Loading></Loading>}
                        {isSubmitting ? "Submitting..." : "Save"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;